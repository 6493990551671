const updateOptions = (options) => {
    const update = {...options};
    if (localStorage.user) {
        update.headers = {
            ...update.headers,
            Authorization: `Basic ${localStorage.getItem('user')}`,
        };
    }
    return update;
}

const fetcher = (url, options) => {
    return fetch(url, updateOptions(options));
}

export { fetcher };
