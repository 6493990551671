import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CustomizedLabeledCheckbox from './CustomizedLabeledCheckbox.jsx';
import * as U from '../../utils';

const Filters = () => {
    const [filterState, setFilterState] = useState({
        classA: true,
        classB: true,
        passenger: true,
        cargo: true,
        tanker: true,
        pleasureCraft: true,
        highSpeed: true,
        fishing: true,
        tsc: true,
        wig: true,
        unspecified: true,
    });

    const handleChange = (event) => {
        setFilterState({...filterState, [event.target.name]: event.target.checked});
        U.applyVesselFilterOnMap(event.target.name, event.target.checked);
    };

    return (
        <Grid container spacing={3}>
            <Grid container direction="column" item>
                <Typography gutterBottom variant="body2">
                    Vessel Class filter
                </Typography>
                <CustomizedLabeledCheckbox
                    checked={filterState.classA}
                    onChange={handleChange}
                    name="classA"
                    label="Class A"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.classB}
                    onChange={handleChange}
                    name="classB"
                    label="Class B"
                />
            </Grid>
            <Grid container direction="column" item>
                <Typography gutterBottom variant="body2">
                    Vessel type filter
                </Typography>
                <CustomizedLabeledCheckbox
                    checked={filterState.passenger}
                    onChange={handleChange}
                    name="passenger"
                    label="Passenger"
                    iconcolor="#00f"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.cargo}
                    onChange={handleChange}
                    name="cargo"
                    label="Cargo"
                    iconcolor="#90ee90"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.tanker}
                    onChange={handleChange}
                    name="tanker"
                    label="Tanker"
                    iconcolor="#f00"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.pleasureCraft}
                    onChange={handleChange}
                    name="pleasureCraft"
                    label="Pleasure Craft"
                    iconcolor="#f0f"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.highSpeed}
                    onChange={handleChange}
                    name="highSpeed"
                    label="High speed craft"
                    iconcolor="#ff0"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.fishing}
                    onChange={handleChange}
                    name="fishing"
                    label="Fishing"
                    iconcolor="#ffa07a"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.tsc}
                    onChange={handleChange}
                    name="tsc"
                    label="Tugs & Special craft"
                    iconcolor="#0ff"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.wig}
                    onChange={handleChange}
                    name="wig"
                    label="Wing in ground"
                    iconcolor="#248cd2"
                />
                <CustomizedLabeledCheckbox
                    checked={filterState.unspecified}
                    onChange={handleChange}
                    name="unspecified"
                    label="Unspecified"
                    iconcolor="#d3d3d3"
                />
            </Grid>
        </Grid>
    );
};

export default Filters;
