import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Navigation from '@mui/icons-material/Navigation';
import {StyledCheckBox, icon, checkedIcon} from './styles'

const CustomizedLabeledCheckbox = (props) => {

    return (
            <FormControlLabel
                control={
                    <StyledCheckBox
                        sx={{p:0}}
                        size="small"
                        inputProps={{'aria-label': 'decorative checkbox'}}
                        // icon={<icon/>}
                        // checkedIcon={<checkedIcon/>}
                        {...props}
                    />
                }
                label={
                    <Typography variant="body2">
                        {props.iconcolor ?
                            <Navigation
                                style={{
                                    fontSize: 12,
                                    color: props.iconcolor,
                                    marginRight: 2,
                                }}
                            />
                            : null}
                        {props.label}
                    </Typography>
                }
            />
    );
};

export default CustomizedLabeledCheckbox;
