import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Switch from '../../../switch';
import {classes} from './styles';

const WifiConfiguration = ({ssid, password, isLocked, onChange}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTextfieldChange = (key, value) => {
        onChange({key, value});
    };

    return (
        <TableContainer sx={classes.root}>
            <Table sx={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>SSID</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                variant="standard"
                                style={{width: 150}}
                                id='ssid'
                                value={ssid}
                                onChange={(e) => handleTextfieldChange('ssid', e.target.value)}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                style={{width: 150}}
                                id='password'
                                value={password}
                                disabled={!isLocked}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => handleTextfieldChange('password', e.target.value)}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>open</Grid>
                                <Grid item>
                                    <Switch
                                        size="small"
                                        checked={isLocked}
                                        onChange={(e) => handleTextfieldChange('isLocked', e.target.checked)}
                                        name="checkedA"
                                    />
                                </Grid>
                                <Grid item>secured</Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
WifiConfiguration.protoTypes = {
    ssid: PropTypes.string,
    password: PropTypes.string,
    isLocked: PropTypes.bool,
    onChange: PropTypes.func
};

WifiConfiguration.defaultProps = {
    ssid: '',
    password: '',
    isLocked: false
};

export default WifiConfiguration;
