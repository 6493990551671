import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RouterIcon from '@mui/icons-material/Router';
import SettingsIcon from '@mui/icons-material/Settings';
import InstallationStatusIcon from '@mui/icons-material/PlaylistAddCheck';

import DashboardPage from './admin/components/dashboard';
import LocalFeedForwarder from './admin/components/localFeedForwarder';
import NetworkSettings from './admin/components/networkSettings';
import StatusPage from './admin/components/statusPage';
import InstallationStatus from './admin/components/installationStatus';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/localfeed",
    name: "LocalFeed",
    icon: RouterIcon,
    component: LocalFeedForwarder,
    layout: "/admin"
  },
  {
    path: "/network-settings",
    name: "NetworkSettings",
    icon: SettingsIcon,
    component: NetworkSettings,
    layout: "/admin"
  },
  {
    path: "/installation-status",
    name: "InstallationStatus",
    icon: InstallationStatusIcon,
    component: InstallationStatus,
    layout: "/admin"
  },
  {
    path: "/box-status-page",
    name: "BoxStatus",
    icon: InfoIcon,
    component: StatusPage,
    layout: "/admin"
  },
];

export default dashboardRoutes;
