import React from 'react';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import MenuItems from './menuItems';
import {Drawer, DrawerHeader} from './styles';

const Menu = ({open, handleMenuClose, routes}) => {
    return (
            <Drawer
                variant="permanent"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleMenuClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <MenuItems routes={routes}/>
            </Drawer>
    );
};

export default Menu;
