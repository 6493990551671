import React from 'react';
import Grid from '@mui/material/Grid';

import MapPositions from './components/mapPositions';
import TableStatistics from './components/tableStatistics';
import {
    AverageMaxDistanceLine,
    MessagePerMinuteLine,
    RealtimeStatisticsOverview,
    MaxDistancePolar,
    PositionsCounterPolar,
} from './components/statistics'
import Hidden from '@mui/material/Hidden';
import SystemInfo from '../systemInfo';

import {StyledPaper as Paper} from './styles';
import './highchartsTheme';

const Dashboard = () => {
    return (
            <Grid container spacing={3}>
                <Hidden smUp>
                    <Grid item xs={12}>
                        <Paper >
                            <SystemInfo/>
                        </Paper>
                    </Grid>
                </Hidden>
                {/* Position Map */}
                <Grid item xs={12}>
                    <Paper >
                        <MapPositions/>
                    </Paper>
                </Grid>
                {/* Counters */}
                <Grid item xs={12} md={6}>
                    <Paper >
                        <RealtimeStatisticsOverview/>
                    </Paper>
                </Grid>
                {/* MessagePerMinuteLine */}
                <Grid item xs={6}>
                    <Paper>
                        <MessagePerMinuteLine/>
                    </Paper>
                </Grid>
                {/* AverageMaxDistanceLine */}
                <Grid item xs={6}>
                    <Paper>
                        <AverageMaxDistanceLine/>
                    </Paper>
                </Grid>
                {/* MaxDistancePolar */}
                <Grid item xs={6}>
                    <Paper >
                        <MaxDistancePolar/>
                    </Paper>
                </Grid>
                {/* PositionsCounterPolar */}
                <Grid item xs={6}>
                    <Paper >
                        <PositionsCounterPolar/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper >
                        <TableStatistics/>
                    </Paper>
                </Grid>
            </Grid>
    );
};

export default Dashboard;
