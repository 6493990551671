import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Filters from './components/filters';
import VesselInfo from './components/vesselInfo';
import './components/leaflet-triangle-vessel-marker.js';

import * as U from './utils';
import {fetcher} from '../../../../../utils';

import C from '../../../../configuration';
import {MapStyle as Map} from './styles';

const MapPositions = () => {
    const [initMap, setInitMap] = useState(false);
    const [focusedVessel, setFocusedVessel] = useState(null);

    useEffect(() => () => U.closeDataSocket(), []);

    useEffect(() => {
        if (!initMap) {
            setInitMap(true);
        } else {
            fetcher(`${C.localPlotApiURI}/ais/stationPosition`)
                .then(response => response.json())
                .then(position => {
                    U.initializeMap(setFocusedVessel, position);
                    U.bindDataSocket();
                });
        }
    }, [initMap]);
    return (
            <Grid container spacing={1} justifyContent="space-between">
                <Grid container
                      direction="column"
                      justifyContent="space-between"
                      item xs={12} sm={3} lg={2}
                >
                    <Grid item>
                        <Filters/>
                    </Grid>
                    <Grid item>
                        {focusedVessel
                            ? <VesselInfo mmsi={focusedVessel}/>
                            : null
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <Map id="mapPositions"/>
                </Grid>
            </Grid>
    );
};

export default MapPositions;
