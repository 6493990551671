/* globals L */

/**
 * Extend Canvas protype for triangle update handler
 */
L.Canvas.prototype._updateTriangle = function (layer) {
    if (!this._drawing || layer._empty()) {
        return null;
    }

    const p = layer._point;
    const ctx = this._ctx;
    const wh = layer._width / 2;
    const hh = layer._height / 2;
    const speed = layer._speed;
    // rotation angle from deg to rad
    const r = layer._rotation !== 0 ? layer._rotation * (Math.PI * 2) / 360 : 0;

    this._layers[layer._leaflet_id] = layer;

    // rotate canvas on triangle center before drawing
    ctx.save();
    ctx.translate(p.x, p.y);
    ctx.rotate(r);
    // draw triangle
    ctx.beginPath();
    if (speed >= 0.5) {
        ctx.moveTo(0, -hh);
        ctx.lineTo(wh, hh * 2);
        ctx.lineTo(-wh, hh * 2);
    } else {
        ctx.arc(0, 0, wh, 0, 2 * Math.PI);
    }
    ctx.closePath();
    // rotate back and restore position
    ctx.restore();

    this._fillStroke(ctx, layer)
};

L.TriangleVesselMarker = L.Path.extend({
    options: {
        fill: true,
        // width {Number} in px
        width: 12,
        // height {Number} in px
        height: 12,
        // rotation (in deg)
        rotation: 0,
        // set default renderer (works only in Canvas renderer)
        renderer: L.canvas()
    },

    initialize: function (latlng, options) {
        L.setOptions(this, options)

        this._latlng = L.latLng(latlng)
        this._width = this.options.width
        this._height = this.options.height
        this._speed = this.options.speed
        this._rotation = this.options.rotation
        this._renderer = this.options.renderer
    },

    /**
     * Set new latlng for triangle
     * @param   {Object}    latlng
     * @return  {?}
     */
    setLatLng: function (latlng) {
        var oldLatLng = this._latlng;
        this._latlng = L.latLng(latlng);
        this.redraw();

        // @event move: Event
        // Fired when the marker is moved via [`setLatLng`](#circlemarker-setlatlng). Old and new coordinates are included in event arguments as `oldLatLng`, `latlng`.
        return this.fire('move', {oldLatLng: oldLatLng, latlng: this._latlng});

        // const oldLatLng = this._latlng;
        // this._latlng = L.latLng(latlng)
        // this.redraw();
        // // return this.fire('move', {latlng: this._latlng})
        // return this.fire('move', {oldLatLng: oldLatLng, latlng: this._latlng});
    },

    /**
     * Get latlng of triangle
     * @return  {Object}
     */
    getLatLng: function () {
        return this._latlng
    },

    /**
     * Set new width for triangle
     * @param   {Number}    width                   Width in px
     * @return  {?}
     */
    setWidth: function (width) {
        this.options.width = this._width = width

        return this.redraw()
    },

    /**
     * Set new height for triangle
     * @param   {Number}    height                  Height in px
     * @return  {?}
     */
    setHeight: function (height) {
        this.options.height = this._height = height

        return this.redraw()
    },

    /**
     * Set new width for triangle
     * @param   {Number}    deg                     Rotation in degrees
     * @return  {?}
     */
    setRotation: function (deg) {
        this.options.rotation = this._rotation = deg

        return this.redraw()
    },

    setSpeed: function (speed) {
        this.options.speed = this._speed = speed

        return this.redraw()
    },

    /**
     * Get width of triangle
     * @return  {Number}    width                  Width in px
     */
    getWidth: function () {
        return this._width
    },

    /**
     * Get height of triangle
     * @return  {Number}    height                 Height in px
     */
    getHeight: function () {
        return this._height
    },

    /**
     * Get rotation of triangle
     * @return  {Number}    height                 Rotation in deg
     */
    getRotation: function () {
        return this._rotation
    },

    /**
     * Set new style
     * @param   {Object}    options
     * @chainable
     */
    setStyle: function (options) {
        var width = (options && options.width) || this._width,
            height = (options && options.height) || this._height

        L.Path.prototype.setStyle.call(this, options)
        this.setWidth(width)
        this.setHeight(height)

        return this
    },

    _project: function () {
        this._point = this._map.latLngToLayerPoint(this._latlng)
        this._updateBounds()
    },

    /**
     * Update pixelbounds
     */
    _updateBounds: function () {
        var w = (this._width / 2) + 5,
            h = (this._height / 2) + 5,
            b = this._clickTolerance(),
            p = [w + b, h + b]

        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p))
    },

    /**
     * Update
     */
    _update: function () {
        if (this._map) {
            this._updatePath()
        }
    },

    /**
     * Update triangle path
     */
    _updatePath: function () {
        this._renderer._updateTriangle(this)
    },

    _empty: function () {
        return this._width && this._height && !this._renderer._bounds.intersects(this._pxBounds)
    },

    // Needed by the `Canvas` renderer for interactivity
    _containsPoint: function (p) {
        return this._pxBounds && this._pxBounds.contains(p)
    }
})

/**
 * Instantiates a triangle vessel marker object given a geographical point,
 * and an optional options object.
 * @param   {Object}    latlng
 * @param   {Object}    options                     [Optional] options
 * @return  {Object}
 */
L.triangleVesselMarker = function (latlng, options) {
    return new L.TriangleVesselMarker(latlng, options)
}
