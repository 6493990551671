import {styled} from '@mui/material/styles';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const RootPaper = styled(Paper,)(({theme}) => (
    {
        minHeight: 300,
    }
));

const StyledButton = styled(Button,)(({theme}) => (
    {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
));

const ActionArea = styled('div',)(({theme}) => (
    {
        marginTop: theme.spacing(2),
    }
));

const StyledSelect = styled(Select,)(({theme}) => (
    {
        minWidth: theme.spacing(10),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
));

const StyledCircularProgress = styled(CircularProgress,)(({theme}) => (
    {
        marginLeft: theme.spacing(10),
    }
));

export {RootPaper, StyledButton, ActionArea, StyledSelect, StyledCircularProgress};
