import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import SettingsEthernetIcon from '@mui/icons-material/Power';
import SettingsEthernetOffIcon from '@mui/icons-material/PowerOff';
import UnknownDeviceIcon from '@mui/icons-material/DeviceUnknown';
import Grid from '@mui/material/Grid';

import WifiIcon from '../wifiIcon';
import WifiResetButton from '../wifiResetButton';

import {fetcher} from '../../../../../utils';

import C from '../../../../configuration';
import {classes} from './styles';

let getActiveConnectionsInterval;
const ConnectionStatus = () => {

    const [activeConnections, setActiveConnections] = useState([]);

    useEffect(() => {
        getActiveConnections();
        getActiveConnectionsInterval = setInterval(() => {
            getActiveConnections();
        }, 10000);
        return () => {
            clearInterval(getActiveConnectionsInterval);
            getActiveConnectionsInterval = 0;
        };
    }, []);

    const getActiveConnections = () => {
        fetcher(`${C.localPlotApiURI}/network/connection/status`)
            .then(response => response.json())
            .then(data => setActiveConnections(Object.values(data)));
    };

    const renderConnection = (connection) => {
        const {
            name,
            type,
            signalStrength,
            isLocked,
            isOnline,
        } = connection;

        let connectionIcon;
        switch (type) {
            case 'ethernet': {
                connectionIcon = isOnline ? <SettingsEthernetIcon/> : <SettingsEthernetOffIcon/>;
                break;
            }
            case 'wifi': {
                connectionIcon = (
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <WifiIcon signalStrength={signalStrength} isLocked={isLocked} isOnline={isOnline}/>
                        {isOnline && connection.Ssid !== 'SLR350Ni hotspot' ?
                            <WifiResetButton interfaceName={connection.interface}/> : null}
                    </Grid>
                );
                break;
            }
            default: {
                connectionIcon = (<UnknownDeviceIcon/>);
            }
        }

        return (
            <ListItem key={connection.interface}>
                <ListItemText
                    primary={name}
                    secondaryTypographyProps={{component: 'div'}}
                    secondary={<>
                        <Typography variant="caption" component="div">
                            {`Type: ${type}`}
                        </Typography>
                        {isOnline && type === 'wifi'
                            ? <Typography variant="caption" component="div">
                                {`SSID: ${connection.Ssid}`}
                            </Typography>
                            : null
                        }
                        {isOnline
                            ? <Typography variant="caption" component="div">
                                {`Mode: ${connection.isStatic ? 'Static' : 'DHCP'}`}
                            </Typography>
                            : null
                        }
                        {isOnline
                            ? <Typography variant="caption" component="div">
                                {`IP: ${connection.ip}`}
                            </Typography>
                            : null
                        }
                        {isOnline
                            ? <Typography variant="caption" component="div">
                                {`Gateway: ${connection.gateway}`}
                            </Typography>
                            : null
                        }
                        <Typography variant="caption" component="div">
                            {`MacAddress: ${connection.HwAddress}`}
                        </Typography>
                    </>}
                />
                <ListItemSecondaryAction>
                    {connectionIcon}
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    return (
        <>
            <Typography variant="h6" gutterBottom align="center">Connections</Typography>
            <Paper sx={classes.root}>
                <List>
                    {activeConnections.map((connection) => renderConnection(connection))}
                </List>
            </Paper>
        </>
    );
};

export default ConnectionStatus;
