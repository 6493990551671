import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import Loader from '../../../../../loader';
import NetworkInterfaceView from '../networkInterfaceView';
import StatusIcon from '../StatusIcon';

const NetworkStatusView = ({networkStatus}) => (
    <>
        {networkStatus === null
            ? <Loader />
            : <TableContainer component={Paper}>
                <Typography variant="h4" align="center" gutterBottom style={{marginTop: 4}}>
                    Network status
                </Typography>
                <Table aria-label="simple table">
                    <TableBody>
                        {networkStatus?.interfaces
                            ?
                            networkStatus.interfaces.map((networkInterface) =>
                                <NetworkInterfaceView key={networkInterface.key} networkInterface={networkInterface} />
                            )
                            : null
                        }
                        <TableRow>
                            <TableCell component="th" scope="row">
                                DNS resolver
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {networkStatus?.dnsServer}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">
                                Ping check DNS server (ICMP)
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <StatusIcon success={networkStatus?.checkICMPDNS === 'success'} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Connectivity DNS check
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <StatusIcon success={networkStatus?.checkDNSFirewall === 'open'} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                DNS resolve check
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <StatusIcon success={networkStatus?.checkDNSResolve === 'success'} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Connectivity MTProxy check
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <StatusIcon success={networkStatus?.checkProxyFirewall === 'open'} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Public IP
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {networkStatus?.publicIpAddress}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </>
);

export default NetworkStatusView;
