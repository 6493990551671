import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const VesselPaper = styled(Paper,)(({theme}) => (
    {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    }));


export {VesselPaper};
