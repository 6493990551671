import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {VesselPaper as Paper} from './styles';

const VesselInfo = (props) => {
    const {
        mmsi,
        name,
        course,
        speed,
        coordinates,
        navigationalStatus,
        legend,
    } = window.stationMap.vessels[`${props.mmsi}`];

    return (
            <Paper
                elevation={3}
            >
                <Typography
                    component={Link}
                    href={`https://www.marinetraffic.com/en/ais/details/ships/mmsi:${mmsi}`}
                    target="_blank"
                    color="textSecondary"
                    noWrap
                    variant="body2"
                >
                    Vessel's details on MarineTraffic
                </Typography>
                <Typography gutterBottom noWrap variant="body2" style={{marginTop: 16}}>
                    <b>Name:</b> {name || '-'}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>MMSI:</b> {mmsi}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>Latitude:</b> {coordinates[0].toFixed(2)}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>Longitude:</b> {coordinates[1].toFixed(2)}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>Speed:</b> {speed ? `${speed.toFixed(2)}  knots` : '-'}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>Course:</b> {course ? `${course} °` : '-'}
                </Typography>
                <Typography gutterBottom noWrap variant="body2">
                    <b>Vessel type:</b> {legend}
                </Typography>
                <Typography noWrap variant="body2">
                    <b>Status:</b> {navigationalStatus || '-'}
                </Typography>
            </Paper>
    );
};

export default VesselInfo;
