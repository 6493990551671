const classes = {
    div: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    }
}

const ContainerTheme = (theme) => {
    return {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(4),
    }
}

export {classes, ContainerTheme};
