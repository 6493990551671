import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import MacAddressView from "./components/macAddressView";
import InputStatusView from "./components/InputStatusView";
import CoordinatesForm from "./components/coordinatesForm";

const InstallationStatus = () => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom align="center">Installation Status</Typography>
            </Grid>
            <Grid item xs={12} md>
                <MacAddressView/>
            </Grid>
            <Grid item xs={12} md>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CoordinatesForm/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputStatusView/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
export default InstallationStatus;
