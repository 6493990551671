import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import UserIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';

import { isUserLoggedIn } from '../../../../../login/utils';
import { fetcher } from '../../../../../utils';

import C from '../../../../configuration';

const UserMenu = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialog, setDialog] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});
    const [settings, setSettings] = React.useState(
        {
            isAuthenticationEnabled: isUserLoggedIn(),
            password: '',
            repeatPassword: ''
        }
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSettingsDialog = () => {
        setDialog(false);
    };

    const handleSettingsUpdate = () => {
        if (settings.isAuthenticationEnabled
            && (
                settings.password.trim().length <= 0
                || settings.password !== settings.repeatPassword
            )
        ) {
            setSnackbar({open: true, message: 'Check the password again', severity: 'error'});
            return;
        }

        fetcher(`${C.localPlotApiURI}/authentication/setSecurity?isAuthenticationEnabled=${settings.isAuthenticationEnabled}&password=${window.btoa(settings.password)}`, {method: 'POST'})
            .then((response) => {
                if (response.status === 200) {
                    setSnackbar({open: true, message: 'Update complete', severity: 'success'});
                    setSettings({
                        password: '',
                        repeatPassword: '',
                        isAuthenticationEnabled: settings.isAuthenticationEnabled
                    })
                    handleCloseSettingsDialog();
                    localStorage.removeItem('user');
                    navigate('/');
                } else {
                    setSnackbar({open: true, message: 'Error on setting update', severity: 'error'});
                }
            });
    }
    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'success'});
    };

    const open = Boolean(anchorEl);
    return (
        <div>
            <IconButton onClick={handleClick}>
                <UserIcon />
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        setDialog(true);
                    }}
                    dense
                >
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </MenuItem>
                {isUserLoggedIn()
                    ? <MenuItem
                        onClick={() => {
                            localStorage.removeItem('user');
                            navigate('/');
                        }}
                    >
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>logout</ListItemText>
                    </MenuItem>
                    : null
                }
            </Menu>
            {dialog
                ? <Dialog
                    open={dialog}
                    onClose={handleCloseSettingsDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialog.title}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{mt: 1}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(e) => {
                                            setSettings({
                                                password: '',
                                                repeatPassword: '',
                                                isAuthenticationEnabled: e.target.checked
                                            })
                                        }}
                                        checked={settings.isAuthenticationEnabled}
                                    />
                                }
                                label="Login"
                            />
                            <TextField
                                disabled={!settings.isAuthenticationEnabled}
                                onChange={(e) => {
                                    setSettings({...settings, password: e.target.value})
                                }}
                                value={settings.password}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                variant="standard"
                                type="password"
                                id="password"
                            />
                            <TextField
                                disabled={!settings.isAuthenticationEnabled}
                                onChange={(e) => {
                                    setSettings({...settings, repeatPassword: e.target.value})
                                }}
                                value={settings.repeatPassword}
                                margin="normal"
                                required
                                fullWidth
                                name="repeat-password"
                                label="Repeat password"
                                variant="standard"
                                type="password"
                                id="repeat-password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSettingsDialog} autoFocus>
                            cancel
                        </Button>
                        <Button
                            onClick={() => handleSettingsUpdate()}
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
                : null
            }
            {snackbar.open
                ?
                <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                : null
            }
        </div>
    );
};

export default UserMenu;
