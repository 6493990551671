import React from 'react';
import Box from '@mui/material/Box';
import PowerIcon from '@mui/icons-material/Power';

import {classes} from './styles';
import Typography from '@mui/material/Typography';

const UnderVoltage = () => {
    return (
            <Box
                sx={classes.root}
            >
                <PowerIcon style={{color: '#bd0c0c'}}/>
                <Typography
                    variant="caption"
                    component="div"
                    style={{color: '#d56060'}}
                >
                    The device is undervolted!
                </Typography>
            </Box>
    );
};

export default UnderVoltage;
