import React, { useEffect, useState } from 'react';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { fetcher } from "../../../../../utils";
import C from "../../../../configuration";
import Alert from "@mui/material/Alert";

const alertInitState = {visible: false, type: '', content: ''};

const CoordinatesForm = () => {
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const [alert, setAlert] = useState(alertInitState);

    useEffect(() => {
        getCoordinates();
    }, []);

    const getCoordinates = () => {
        fetcher(`${C.localPlotApiURI}/ais/stationPosition`)
            .then(response => response.json())
            .then(data => {
                setLat(data.lat);
                setLon(data.lon);
            });
    }

    const handleLatChange = (event) => {
        const value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(value) || value === '') {
            setLat(value);
        }
    };
    const handleLonChange = (event) => {
        const value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(value) || value === '') {
            setLon(value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        // You can perform actions with the input values here
        fetcher(`${C.localPlotApiURI}/ais/stationPosition`,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({lat, lon})
            })
            .then(response => {
                if (response.status !== 200) {
                    setAlert({visible: true, type: 'error', content: response.error});
                    resetAlert();
                    return;
                }
                setAlert({visible: true, type: 'success', content: 'Location applied'});
                resetAlert();
            })
            .catch(() => {
                setAlert({visible: true, type: 'error', content: 'Could not apply changes!'});
                resetAlert();
            });
    };

    const resetAlert = () => {
        return setTimeout(() => {
            setAlert(alertInitState)
        }, 3000);
    }

    return (
        <Paper style={{padding: '20px'}}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Coordination Form
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Latitude"
                            variant="standard"
                            fullWidth
                            value={lat}
                            onChange={handleLatChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Longitude"
                            variant="standard"
                            fullWidth
                            value={lon}
                            onChange={handleLonChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{marginRight: '20px'}}
                            > Submit </Button>
                            {alert.visible && <Alert severity={alert.type}>{alert.content}</Alert>}
                        </div>
                    </Grid>

                </Grid>
            </form>
        </Paper>
    );
}
export default CoordinatesForm;
