import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { fetcher } from '../../../../../../utils';

import C from '../../../../../configuration';

let distanceLineChartInterval;
const AverageMaxDistanceLine = () => {
    const [isChartInitialized, setChartInitialized] = useState(false);
    const [chartOptions, setChartOptions] = useState(null);

    useEffect(() => {
        getDistanceLineChart(initializeChart);
        return () => {
            clearInterval(distanceLineChartInterval);
            distanceLineChartInterval = 0;
        };
    }, []);

    const initializeChart = ({averageTimeline, maxTimeline}) => {
        setChartOptions({
            chart: {
                zoomType: 'x',
                renderTo: 'lineAISMessages',
                events: {
                    load: function () {
                        const averageSeries = this.series[0];
                        const maxSeries = this.series[1];
                        const chart = this;
                        distanceLineChartInterval = setInterval(() => {
                            getDistanceLineChart(({averageTimeline, maxTimeline}) => {
                                if (averageSeries.data.length !== averageTimeline.length) {
                                    averageSeries.setData(averageTimeline);
                                    maxSeries.setData(maxTimeline);
                                    chart.redraw();
                                }
                            });
                        }, 60000);
                    }
                }
            },
            title: {
                text: 'Average & Max Distance',
            },
            subtitle: {
                text: document.ontouchstart === undefined ?
                    'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                title: {
                    text: 'Distance in NM',
                },
            },
            legend: {
                enabled: true,
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, `rgba(0, 0, 0, 0)`]  // fully transparent -> same effect
                        ],
                    },
                    marker: {
                        radius: 2,
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1,
                        },
                    },
                    threshold: 0,
                },
            },
            series: [
                {
                    type: 'area',
                    name: 'Average Distance',
                    color: '#2B908F',
                    data: averageTimeline,
                },
                {
                    type: 'area',
                    name: 'Max Distance',
                    color: '#90ED7D',
                    data: maxTimeline,
                }
            ],
        });
        setChartInitialized(true);
    };

    const getDistanceLineChart = (cb) => {
        fetcher(`${C.localPlotApiURI}/ais/distanceLineChart`)
            .then(response => response.json())
            .then(data => {
                cb(data);
            });
    };

    return (
        isChartInitialized ?
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
            : null
    );
};

export default AverageMaxDistanceLine;
