import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import Typography from '@mui/material/Typography';
import Moment from 'moment';

import C from '../../../../../configuration';

const Counters = () => {
    const [distinctVessels, setDistinctVessels] = useState(0);
    const [classACounter, setClassACounter] = useState(0);
    const [classBCounter, setClassBCounter] = useState(0);
    const [uptime, setUptime] = useState(null);

    useEffect(() => {
        const socket = io(C.localPlotSocketURI, {path:'/socket'});

        socket.on('realtimeStats-counters-init', (message) => {
            setDistinctVessels(message.distinctCounter);
            setClassACounter(message.classACounter);
            setClassBCounter(message.classBCounter);
        });
        socket.on('uptime', (message) => {
            setUptime(message);
        });
        socket.on('realtimeStats-counters', (message) => {
            setDistinctVessels(message.distinctCounter);
            setClassACounter(message.classACounter);
            setClassBCounter(message.classBCounter);
        });

        return () => {
            socket.close();
        };
    }, []);
    return (
        <>
            <Typography variant="h3" align="center" gutterBottom>
                <strong>{distinctVessels}</strong>
                <Typography>
                    distinct vessels
                </Typography>
            </Typography>
            <Typography variant="h3" align="center" gutterBottom>
                <strong>{classACounter}</strong>
                <Typography>
                    Class A
                </Typography>
            </Typography>
            <Typography variant="h3" align="center" gutterBottom>
                <strong>{classBCounter}</strong>
                <Typography>
                    Class B
                </Typography>
            </Typography>
            {uptime
                ? <Typography align="center">
                    Uptime: <strong> {new Moment(uptime).fromNow(true)} </strong>
                </Typography>
                : null
            }
        </>
    );
};

export default Counters;
