/* globals L */
import io from 'socket.io-client';
import C from '../../../../configuration';

let socket = null;

const initializeMap = (onVesselClick = () => console.log('vessel click'), {lat, lon}) => {
    const stationLat = lat;
    const stationLon = lon;
    const stationMap = L.map('mapPositions', {preferCanvas: true}).setView([stationLat, stationLon], 10);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(stationMap);
    const antennaMarker = getAntennaMarker(stationLat, stationLon).addTo(stationMap);
    window.stationMap = stationMap;
    window.stationMap.antennaMarker = antennaMarker;
    window.stationMap.vessels = {};
    window.stationMap.filters = {
        classA: true,
        classB: true,
        passenger: true,
        cargo: true,
        tanker: true,
        pleasureCraft: true,
        highSpeed: true,
        fishing: true,
        tsc: true,
        wig: true,
        unspecified: true,
    };
    window.stationMap.onVesselClick = onVesselClick;
};

const getAntennaMarker = (lat, lon) => {
    const stationIcon = L.icon({
        iconUrl: '/antenna.png',
        iconSize: [30, 30],
    });
    return L.marker([lat, lon], {icon: stationIcon, interactive: false})
};

const applyVesselFilterOnMap = (filter, isVisible = true) => {
    window.stationMap.filters[filter] = isVisible;
    updateFilteredVessels();
};

const bindDataSocket = () => {
    socket = io(C.localPlotSocketURI, {path:'/socket'});
    socket.on("vesselPositions-init", (message) => {
        clearVesselsFromMap();
        window.stationMap.vessels = {};
        message.forEach((vesselInfo) => {
            createVessel(vesselInfo);
        });
        drawVesselsOnMap();
    });

    socket.on("vesselPositions-update", (vesselInfo) => {
        const {mmsi, coordinates} = vesselInfo;
        if (!coordinates) {
            return
        }
        const vessels = window.stationMap.vessels;
        if (Object.keys(vessels).includes(`${mmsi}`)) {
            vessels[`${mmsi}`] = Object.assign(vessels[`${mmsi}`], vesselInfo)
            const {marker} = vessels[`${mmsi}`];
            updateVesselMarker(marker, vesselInfo);
        } else {
            createVessel(vesselInfo);
            drawVesselOnMap(mmsi);
        }
        updateFilteredVessels();
    });

    socket.on("stationPosition-update", ({lat, lon}) => {
        window.stationMap.antennaMarker.setLatLng([lat, lon]);
    });

    return socket;
};

const closeDataSocket = () => {
    socket && socket.close();
    window.stationMap.remove();
};

const updateFilteredVessels = () => {
    const activeFilters = Object.keys(window.stationMap.filters).filter((filterType) => window.stationMap.filters[filterType]);
    const inactiveFilters = Object.keys(window.stationMap.filters).filter((filterType) => !window.stationMap.filters[filterType]);

    Object.values(window.stationMap.vessels)
        .filter(({marker, typeFilter, classType}) => {
            return window.stationMap.hasLayer(marker) &&
                (inactiveFilters.includes(typeFilter) ||
                    inactiveFilters.includes(`class${classType}`));
        })
        .forEach(({marker}) => {
            marker.remove();
        });

    Object.values(window.stationMap.vessels)
        .filter(({marker, typeFilter, classType}) => {
            return !window.stationMap.hasLayer(marker) &&
                activeFilters.includes(typeFilter) &&
                activeFilters.includes(`class${classType}`);
        })
        .forEach(({marker}) => {
            marker.addTo(window.stationMap);
        });
};

const createVessel = (vessel) => {
    window.stationMap.vessels[`${vessel.mmsi}`] = {
        ...vessel,
        marker: createVesselMarker(vessel),
    };
};

const drawVesselsOnMap = (filter = null) => {
    const vessels = window.stationMap.vessels;
    Object.values(vessels).forEach(({marker}) => {
        marker.addTo(window.stationMap);
    });
};

const drawVesselOnMap = (mmsi) => {
    const vessels = window.stationMap.vessels;
    vessels[`${mmsi}`].marker.addTo(window.stationMap);
};

const clearVesselsFromMap = () => {
    const vessels = window.stationMap.vessels;
    Object.values(vessels).forEach(({marker}) => {
        marker.remove();
    });
};

const createVesselMarker = ({coordinates, course, color, speed, mmsi, name}) =>
    L.triangleVesselMarker(coordinates, {
        rotation: course,         // triangle rotation in degrees (default: 0)
        width: 10,         // width of the base of triangle (default: 24)
        height: 12,          // height of triangle (default: 24)
        color: '#000',
        weight: 1,
        fillColor: color,
        fillOpacity: 1,
        speed: speed,
    }).on('click', () => {
        window.stationMap.onVesselClick(mmsi);
        window.stationMap.invalidateSize();
    }).bindTooltip(`Name: ${name ? name.trim() : '-'}`);

const updateVesselMarker = (vesselMarker, vesselData) => {
    const {color, course, speed, coordinates, name} = vesselData;
    vesselMarker.setStyle({fillColor: color,});
    vesselMarker.setRotation(course);
    vesselMarker.setSpeed(speed);
    vesselMarker.setLatLng(coordinates);
    vesselMarker._tooltip.setContent(`Name: ${name ? name.trim() : '-'}`);
};

export {
    initializeMap,
    bindDataSocket,
    closeDataSocket,
    applyVesselFilterOnMap,
};
