import React, {useEffect, useState} from 'react';
import {Route, Navigate, Routes} from 'react-router-dom';
import {Container, Box} from '@mui/material';

import AppBar from './components/appBar';
import Menu from './components/menu';
import Copyright from './components/copyright';
import Loader from '../loader';

import routesConfig from '../routes.js';
import {isUserLoggedIn} from '../login/utils';
import {fetcher} from '../utils';

import {classes, ContainerTheme} from './styles';
import C from './configuration';

const Admin = () => {

    const [open, setOpen] = useState(false);
    const [isAuthenticationEnabled, setIsAuthenticationEnabled] = useState(null);
    const [homePath, setHomePath] = useState(null);
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = () => {
        fetcher(`${C.localPlotApiURI}/authentication/info`)
            .then(response => {
                if (!response.ok && response.status === 404) {
                    localStorage.clear();
                    getUserInfo();
                }
                return response.json()
            })
            .then(data => {
                if (!data.isAuthenticationEnabled) {
                    localStorage.setItem('user', window.btoa('user:user'));
                }
                setIsAuthenticationEnabled(data.isAuthenticationEnabled);
                let homeName;
                const activeComponents = data.userSettings
                    .filter((component) => component.isActive)
                    .map((component) => {
                        if (component.isHome) {
                            homeName = component.name;
                        }
                        return component.name;
                    });
                setRoutes(routesConfig.filter((route) => activeComponents.includes(route.name)))
                setHomePath(`/admin${routesConfig.filter((route) => route.name === homeName)[0].path}`);
            })
            .catch(err => {
                console.error(`caught error: ${err}`);
            });
    }

    const switchRoutes = (
            <Routes>
                {homePath !== null
                    ? <Route path="/" element={<Navigate replace to={homePath}/>}/>
                    : null}
                {routes.map((route) => {
                    if (route.layout === "/admin") {
                        if (isAuthenticationEnabled && !isUserLoggedIn()) {
                            return (
                                <Route path={route.path} key={route.path} element={<Navigate replace to='/login'/>}/>
                            )
                        } else {
                            return (
                                <Route
                                    path={route.path}
                                    key={route.path}
                                    element={<route.component/>}
                                />
                            )
                        }
                    }
                })}
            </Routes>
        );

    return (
        <>
            {isAuthenticationEnabled === null
                ? <Loader/>
                : <Box sx={classes.div}>
                    <AppBar
                        open={open}
                        handleLeftMenuOpen={() => {
                            setOpen(true);
                        }}
                    />
                    <Menu
                        routes={routes}
                        open={open}
                        handleMenuClose={() => {
                            setOpen(false);
                        }}
                    />
                    <Box component="main" sx={classes.content}>
                        <Container maxWidth="xxl" sx={theme => ContainerTheme(theme)}>
                            {switchRoutes}
                            <Box pt={4}>
                                <Copyright/>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            }
        </>
    )
};

export default Admin;
