import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {classes} from './styles';

const StatusIcon = ({success}) => {

    return success
        ? <CheckCircleIcon sx={classes.pass} />
        : <CancelIcon sx={classes.noPass} />
};


export default StatusIcon;
