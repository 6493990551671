import React from 'react';
import Loader from '../../../../../loader';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const DeviceInfoView = ({deviceInfo}) => {
    return (
        <>
            {deviceInfo === null
                ? <Loader />
                : <TableContainer component={Paper}>
                    <Typography variant="h4" align="center" gutterBottom style={{marginTop: 4}}>
                        Device information
                    </Typography>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Device Name
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {deviceInfo?.deviceName}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

export default DeviceInfoView;
