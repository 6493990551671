import React from 'react';
import ResetWifiIcon from '@mui/icons-material/SettingsBackupRestore';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { fetcher } from '../../../../../utils';

import C from '../../../../configuration';

let agreeButtonTimeout = null;
const WifiResetButton = ({interfaceName}) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isAgreeButtonDisabled, setIsAgreeButtonDisabled] = React.useState(true);
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});

    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'success'});
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);

        if (agreeButtonTimeout !== null) {
            clearTimeout(agreeButtonTimeout);
            agreeButtonTimeout = null;
        }

        if (!isAgreeButtonDisabled) {
            setIsAgreeButtonDisabled(true);
        }
    };

    const handleResetAction = () => {
        handleCloseDialog();
        let fetchData = {
            method: 'POST',
            body: JSON.stringify({
                interface: interfaceName
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        fetcher(`${C.localPlotApiURI}/network/wifi/reset`, fetchData)
            .then((response) => {
                if (response.status === 200) {
                    setSnackbar({open: true, message: 'Deleting WIFI settings', severity: 'success'});
                } else {
                    setSnackbar({open: true, message: 'Deleting WIFI settings failed', severity: 'error'});
                }
            });
    };

    if (isDialogOpen && agreeButtonTimeout === null) {
        agreeButtonTimeout = setTimeout(() => {
            setIsAgreeButtonDisabled(false);
        }, 3000);
    }

    return (
        <>
            <Tooltip title="Reset wifi">
                <IconButton
                    aria-label="Reset wifi"
                    onClick={() => {
                        setIsDialogOpen(true)
                    }}
                >
                    <ResetWifiIcon />
                </IconButton>
            </Tooltip>
            {snackbar.open
                ?
                <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                : null
            }
            {isDialogOpen
                ? <Dialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure you want to reset the Wifi settings?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By resetting WIFI settings, existing wifi connection will be deleted and hotspot mode will
                            be
                            available.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>
                            Disagree
                        </Button>
                        <Button
                            disabled={isAgreeButtonDisabled}
                            onClick={handleResetAction}
                        >
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                : null
            }
        </>
    );
};

export default WifiResetButton;
