import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import io from 'socket.io-client';

import C from '../../../../../configuration';

let socket;
const VesselTypePie = () => {
    const [isChartInitialized, setChartInitialized] = useState(false);
    const [pieChartOptions, setPieChartOptions] = useState(null);

    useEffect(() => {
        socket = io(C.localPlotSocketURI, {path:'/socket'});
        socket.on('realtimeStats-vesselTypePie-init', (message) => {
            setPieChartOptions({
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    events: {
                        load: function () {
                            const series = this.series[0];
                            socket.on('realtimeStats-vesselTypePie', (message) => {
                                series.setData(message, true);
                            });
                        }
                    }
                },
                title: {
                    text: 'Vessel categories'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> <br/> <b>{point.y}</b> vessels'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true,
                        animation: false
                    }
                },
                series: [{
                    name: 'Vessel types',
                    colorByPoint: true,
                    data: message
                }]
            });
            setChartInitialized(true);
        });
        return () => {
            socket.close();
        };
    }, []);

    return (
        isChartInitialized ?
            <HighchartsReact
                highcharts={Highcharts}
                options={pieChartOptions}
            />
            : null
    );
};

export default VesselTypePie;
