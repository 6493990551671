import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import WifiIcon from '../../../wifiIcon';

import {fetcher} from '../../../../../../../utils';

import {classes} from './styles';
import C from '../../../../../../configuration';
import ListItemButton from "@mui/material/ListItemButton";

const WifiList = ({onClick}) => {

    const [wifiList, setWifiList] = useState([]);
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        scanWifi();
    }, []);

    const handleConnectionClick = (event) => {
        const {ssid, isLocked} = wifiList.filter((info) => info.ssid === event.currentTarget.id)[0];
        onClick(ssid, isLocked);
    };

    const scanWifi = () => {
        setIsScanning(true);
        fetcher(`${C.localPlotApiURI}/network/wifi/scan`)
            .then(response => response.json())
            .then(data => setWifiList(data))
            .finally(() => setTimeout(() => setIsScanning(false), 5000));
    };


    const renderWifiConnection = (connectionInfo) => {
        const {
            ssid,
            signalStrength,
            isLocked,
        } = connectionInfo;
        return (
            <ListItemButton
                key={ssid}
                id={ssid}
                dense
                button
                onClick={handleConnectionClick}
            >
                <ListItemText
                    primary={ssid}
                />
                <ListItemSecondaryAction>
                    <WifiIcon signalStrength={signalStrength} isLocked={isLocked}/>
                </ListItemSecondaryAction>
            </ListItemButton>
        )
    };

    return (
            <Paper sx={classes.root}>
                <Typography variant="h6" gutterBottom>WiFi connections</Typography>
                <List sx={classes.wifiList}>
                    {wifiList.map((connectionInfo) => renderWifiConnection(connectionInfo))}
                </List>
                <Button
                    size="small"
                    variant="contained"
                    onClick={scanWifi}
                    fullWidth
                >
                    Scan Wifi
                    {isScanning
                        ? <CircularProgress
                            sx={classes.circularProgress}
                            color="inherit"
                            size={10}
                        />
                        : null
                    }
                </Button>
            </Paper>
    );
};

WifiList.protoTypes = {
    onClick: PropTypes.func
};

WifiList.defaultProps = {
    onClick: (e) => {
        console.log(e);
    }
};

export default WifiList;
