import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import grey from '@mui/material/colors/grey';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Admin from './admin';
import Login from './login';

const App = () => {
    const prefersDarkMode = true;
    const theme = React.useMemo(
        () => createTheme({
            palette: {
                mode: prefersDarkMode ? 'dark' : 'light',
            },
            overrides: {
                MuiStepIcon: {
                    root: {
                        "&$active": {
                            color: grey[50],
                        },
                        "&$completed": {
                            color: grey[300],
                        }
                    },
                    text: {
                        fill: '#303030'
                    }
                },
                MuiInput: {
                    underline: {
                        "&:after": {
                            borderBottom: '2px solid #fff',
                        },
                    },
                },
                MuiFormLabel: {
                    root: {
                        "&$focused": {
                            color: 'white',
                        }
                    },
                    focused: {}
                },
                MuiOutlinedInput: {
                    root: {
                        '&$focused $notchedOutline': {
                            borderColor: 'white',
                        },
                    },
                },
            },
        }),[prefersDarkMode],
    );
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <Routes>
                    <Route path="/admin/*" element={<Admin/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<Navigate replace to="/admin"/>}/>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
