import React from 'react';
import Loader from '../../../../../loader';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StatusIcon from '../StatusIcon';

const ApplicationStatusView = ({applicationStatus}) => {
    return (
        <>
            {applicationStatus === null
                ? <Loader />
                : <TableContainer component={Paper}>
                    <Typography variant="h4" align="center" gutterBottom style={{marginTop: 4}}>
                        Application status
                    </Typography>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    VDR input
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StatusIcon success={applicationStatus?.vdrInput === 'pass'} />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Gateway input
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StatusIcon success={applicationStatus?.gatewayInput === 'pass'} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Serial input
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StatusIcon success={applicationStatus?.serialInput === 'pass'} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Broadcast AIS data
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StatusIcon success={applicationStatus?.aisOutput === 'pass'} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Broadcast non-AIS data
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StatusIcon success={applicationStatus?.nonAisOutput === 'pass'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

export default ApplicationStatusView;
