const classes = {
    pass: {
        color: 'lightgreen',
    },
    noPass: {
        color: 'red'
    },
};

export {classes};
