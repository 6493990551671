import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Switch from '../../../switch';
import {classes} from './styles';

const IpConfiguration = ({address, gateway, netmask, isStaticEnabled, onChange}) => {

    const handleTextfieldChange = (key, value) => {
        onChange({key, value});
    };

    return (
        <TableContainer sx={classes.root}>
            <Table sx={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>IP address</TableCell>
                        <TableCell>Gateway</TableCell>
                        <TableCell>Netmask</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                variant="standard"
                                style={{width: 150}}
                                id={'address'}
                                value={address}
                                disabled={!isStaticEnabled}
                                onChange={(e) => handleTextfieldChange('address', e.target.value)}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                variant="standard"
                                style={{width: 150}}
                                id={'gateway'}
                                value={gateway}
                                disabled={!isStaticEnabled}
                                onChange={(e) => handleTextfieldChange('gateway', e.target.value)}
                            />
                        </TableCell>
                        <TableCell>
                            <Select
                                variant="standard"
                                style={{width: 150}}
                                id={'netmask'}
                                value={netmask}
                                disabled={!isStaticEnabled}
                                onChange={(e) => handleTextfieldChange('netmask', e.target.value)}
                            >
                                <MenuItem value={32}>255.255.255.255</MenuItem>
                                <MenuItem value={31}>255.255.255.254</MenuItem>
                                <MenuItem value={30}>255.255.255.252</MenuItem>
                                <MenuItem value={29}>255.255.255.248</MenuItem>
                                <MenuItem value={28}>255.255.255.240</MenuItem>
                                <MenuItem value={27}>255.255.255.224</MenuItem>
                                <MenuItem value={26}>255.255.255.192</MenuItem>
                                <MenuItem value={25}>255.255.255.128</MenuItem>
                                <MenuItem value={24}>255.255.255.0</MenuItem>
                                <MenuItem value={23}>255.255.254.0</MenuItem>
                                <MenuItem value={22}>255.255.252.0</MenuItem>
                                <MenuItem value={21}>255.255.248.0</MenuItem>
                                <MenuItem value={20}>255.255.240.0</MenuItem>
                                <MenuItem value={19}>255.255.224.0</MenuItem>
                                <MenuItem value={18}>255.255.192.0</MenuItem>
                                <MenuItem value={17}>255.255.128.0</MenuItem>
                                <MenuItem value={16}>255.255.0.0</MenuItem>
                                <MenuItem value={15}>255.254.0.0</MenuItem>
                                <MenuItem value={14}>255.252.0.0</MenuItem>
                                <MenuItem value={13}>255.248.0.0</MenuItem>
                                <MenuItem value={12}>255.240.0.0</MenuItem>
                                <MenuItem value={11}>255.224.0.0</MenuItem>
                                <MenuItem value={10}>255.192.0.0</MenuItem>
                                <MenuItem value={9}>255.128.0.0</MenuItem>
                                <MenuItem value={8}>255.0.0.0</MenuItem>
                                <MenuItem value={7}>254.0.0.0</MenuItem>
                                <MenuItem value={6}>252.0.0.0</MenuItem>
                                <MenuItem value={5}>248.0.0.0</MenuItem>
                                <MenuItem value={4}>240.0.0.0</MenuItem>
                                <MenuItem value={3}>224.0.0.0</MenuItem>
                                <MenuItem value={2}>192.0.0.0</MenuItem>
                                <MenuItem value={1}>128.0.0.0</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell align="center">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>dhcp</Grid>
                                <Grid item>
                                    <Switch
                                        size="small"
                                        checked={isStaticEnabled}
                                        onChange={(e) => handleTextfieldChange('isStaticEnabled', e.target.checked)}
                                        name="checkedA"
                                    />
                                </Grid>
                                <Grid item>static</Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

IpConfiguration.protoTypes = {
    gateway: PropTypes.string,
    address: PropTypes.string,
    netmask: PropTypes.number,
    isStaticEnabled: PropTypes.bool,
    onChange: PropTypes.func
};

IpConfiguration.defaultProps = {
    gateway: '',
    address: '',
    netmask: 24,
    isStaticEnabled: false
};
export default IpConfiguration;
