const classes = {
    root: {
        padding: 2,
        minHeight: 200,
    },
    table: {
        marginBottom: 4
    },
};

export {classes};
