import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

import SystemInfo from '../systemInfo';
import PowerButton from './components/powerButton';
import UserMenu from './components/userMenu';
import {StyledAppBar, menuStyle} from './styles';

const AppBar = ({open, handleLeftMenuOpen}) => {
    return (
        <StyledAppBar
            position="fixed"
            color="default"
            open={open}
        >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleLeftMenuOpen}
                    sx={theme => menuStyle(theme, open)}
                >
                    <MenuIcon/>
                </IconButton>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <img
                            style={{padding: 2}}
                            src="/LogoDesktop.png"
                            alt="MarineTraffic logo"
                            title="MarineTraffic"
                            width="32"
                            height="32"
                        />
                    </Grid>
                    <Hidden xsDown>
                        <Grid item>
                            <SystemInfo/>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Grid container direction="row">
                            <PowerButton/>
                            <UserMenu/>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </StyledAppBar>
    );
};

export default AppBar;
