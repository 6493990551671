const classes = {
    root: {
        // marginRight: 4,
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
    },
};

export {classes};
