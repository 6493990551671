import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import C from '../admin/configuration.js';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import {fetcher} from "../utils";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        fetcher(`${C.localPlotApiURI}/authentication/info`)
            .then(response => response.json())
            .then(data => {
                if (!data.isAuthenticationEnabled) {
                    navigate("/");
                }
            });
    }, []);
    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'error'});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch(`${C.localPlotApiURI}/authentication/login`, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Basic ${window.btoa(`${data.get('username')}:${window.btoa(`${data.get('password')}`)}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('user', window.btoa(`${data.get('username')}:${window.btoa(`${data.get('password')}`)}`));
                navigate('/');
            } else {
                setSnackbar({open: true, message: 'Login failed', severity: 'error'});
            }
        });
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        variant="standard"
                        name="username"
                        autoComplete="username"
                        defaultValue="user"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        variant="standard"
                        // type="password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        autoFocus
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </InputAdornment>)
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                </Box>
                {snackbar.open
                    ?
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    : null
                }
            </Box>
        </Container>
    );
};

export default Login;
