import React from 'react';
import PropTypes from 'prop-types';
import SignalWifi0Bar from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1Bar from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2Bar from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3Bar from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4Bar from '@mui/icons-material/SignalWifi4Bar';
import SignalWifi1BarLocked from '@mui/icons-material/SignalWifi1BarLock';
import SignalWifi2BarLocked from '@mui/icons-material/SignalWifi2BarLock';
import SignalWifi3BarLocked from '@mui/icons-material/SignalWifi3BarLock';
import SignalWifi4BarLocked from '@mui/icons-material/SignalWifi4BarLock';
import SignalWifiOff from '@mui/icons-material/SignalWifiOff';

const WifiIcon = ({signalStrength, isLocked, isOnline}) => {
    if (!isOnline) {
        return <SignalWifiOff />;
    }
    if (signalStrength > 20 && signalStrength <= 40) {
        return isLocked ? <SignalWifi1BarLocked /> : <SignalWifi1Bar />;
    } else if (signalStrength > 40 && signalStrength <= 60) {
        return isLocked ? <SignalWifi2BarLocked /> : <SignalWifi2Bar />;
    } else if (signalStrength > 60 && signalStrength <= 80) {
        return isLocked ? <SignalWifi3BarLocked /> : <SignalWifi3Bar />;
    } else if (signalStrength > 80 && signalStrength <= 100) {
        return isLocked ? <SignalWifi4BarLocked /> : <SignalWifi4Bar />;
    }
    return <SignalWifi0Bar />;
};

WifiIcon.protoTypes = {
    signalStrength: PropTypes.string,
    isLocked: PropTypes.bool
};

WifiIcon.defaultProps = {
    signalStrength: 100,
    isLocked: false,
    isOnline: true,
};

export default WifiIcon;
