import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';

import NetworkStatusView from './components/networkStatusView';
import ApplicationStatusView from './components/applicationStatusView';
import {fetcher} from '../../../utils';

import C from '../../configuration';
import DeviceInfoView from "./components/DeviceInformationView";

let systemInfoInterval;
const StatusPage = () => {

    const [networkStatus, setNetworkStatus] = useState(null);
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [deviceInfo, setDeviceInfo] = useState(null);

    useEffect(() => {
        getNetworkStatus();
        getApplicationStatus();
        getDeviceInfo();
        systemInfoInterval = setInterval(() => {
            getNetworkStatus();
            getApplicationStatus();
        }, 10000);
        return () => {
            clearInterval(systemInfoInterval);
            systemInfoInterval = 0;
        };
    }, []);

    const getNetworkStatus = () => {
        fetcher(`${C.localPlotApiURI}/network/box-status`)
            .then(response => response.json())
            .then(data => setNetworkStatus(data));
    };

    const getApplicationStatus = () => {
        fetcher(`${C.localPlotApiURI}/applicationStatus/status`)
            .then(response => response.json())
            .then(data => setApplicationStatus(data));
    };

    const getDeviceInfo = () => {
        fetcher(`${C.localPlotApiURI}/system/device-info`)
            .then(response => response.json())
            .then(data => setDeviceInfo(data));
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md>
                <NetworkStatusView networkStatus={networkStatus}/>
            </Grid>
            <Grid item xs={12} md>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ApplicationStatusView applicationStatus={applicationStatus}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DeviceInfoView deviceInfo={deviceInfo}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StatusPage;
