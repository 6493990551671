import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import {classes} from './styles';

const CircularProgressWithLabel = (props) => {
    const {
        label,
        value,
        maxvalue,
        icon,
        suffix
    } = props;

    const circularValue = (value * 100) / maxvalue;
    let color;
    if (circularValue > 80) {
        color = '#bd0c0c'
    } else if (circularValue > 60) {
        color = '#bda945'
    } else {
        color = '#276c27'
    }

    return (
            <Box
                sx={classes.root}
            >
                <Box sx={{position: 'relative', display: 'inline-flex'}}>
                    <CircularProgress
                        variant="determinate"
                        {...props}
                        color="primary"
                        style={{color}}
                        value={circularValue}
                        size={70}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        {icon}
                        <Typography variant="caption" component="div" color="textPrimary">
                            {`${props.value}${suffix}`}
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            color="textPrimary"
                        >
                            {label}
                        </Typography>
                    </Box>
                </Box>
            </Box>
    );
};

CircularProgressWithLabel.protoTypes = {
    label: PropTypes.string,
    value: PropTypes.number.isRequired,
    maxvalue: PropTypes.number,
    suffix: PropTypes.string,
    icon: PropTypes.object,
};

CircularProgressWithLabel.defaultProps = {
    label: '',
    maxvalue: 100,
    suffix: '%',
};

export default CircularProgressWithLabel;
