import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const RealTimeStatisticsGrid = styled(Grid,)(({theme}) => (
    {
        height: '100%',
        backgroundColor: theme.palette.background.default,
    }
));

export {RealTimeStatisticsGrid};
