import React, { useEffect, useState } from 'react';
import CpuIcon from '@mui/icons-material/Memory';
import MemoryIcon from '@mui/icons-material/Memory';
import TemperatureIcon from '@mui/icons-material/AcUnit';

import Grid from '@mui/material/Grid';

import UnderVoltage from './components/underVoltage';
import CircularProgressWithLabel from './components/circularProgressWithLabel';

import { fetcher } from '../../../utils';

import C from '../../configuration';

let systemInfoInterval;
const SystemInfo = () => {
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        getSystemInfo();
        systemInfoInterval = setInterval(() => {
            getSystemInfo();
        }, 10000);
        return () => {
            clearInterval(systemInfoInterval);
            systemInfoInterval = 0;
        };
    }, []);

    const getSystemInfo = () => {
        fetcher(`${C.localPlotApiURI}/system/info`)
            .then(response => response.json())
            .then(data => {
                setSystemInfo(data)
            }).catch(err => {
                console.error(`Could not get systemInfo: ${err}`);
        });
    };

    if (Object.keys(systemInfo).length === 0) {
        return <div />;
    }

    return (<Grid container style={{margin:4}}>
        {systemInfo.cpu_undervolted
            ? <UnderVoltage />
            : null
        }
        <CircularProgressWithLabel
            label="CPU"
            value={systemInfo.total_cpu_usage}
            icon={<CpuIcon fontSize="small" />}
        />
        <CircularProgressWithLabel
            label="RAM"
            value={systemInfo.used_memory}
            maxvalue={systemInfo.total_memory}
            suffix="MB"
            icon={<MemoryIcon fontSize="small" />}
        />
        <CircularProgressWithLabel
            label="TEMP"
            value={systemInfo.temperature_cpu}
            maxvalue={85}
            icon={<TemperatureIcon fontSize="small" />}
            suffix="°"
        />
    </Grid>);
};

export default SystemInfo;
