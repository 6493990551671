import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import {fetcher} from '../../../utils';

import C from '../../configuration';
import {classes} from './styles';

const LocalFeedForwarder = () => {

    const [blackListFeed, setBlackListFeed] = useState([]);
    const [newFeed, setNewFeed] = useState({host: '', port: '', notes: ''});
    const [errorField, setErrorField] = useState({host: false, port: false});
    const [errorMessage, setErrorMessage] = useState('');
    const [editableId, setEditableId] = useState(null);
    const [localFeedList, setLocalFeedList] = useState({});

    const handleCloseErrorMessage = () => {
        setErrorMessage('');
    };

    useEffect(() => {
        getLocalFeedList();
        getLocalFeedBlackList();
    }, []);

    const getLocalFeedList = () => {
        fetcher(`${C.localPlotApiURI}/localFeed/getFeedList`)
            .then(response => response.json())
            .then(data => setLocalFeedList(data));
    };

    const getLocalFeedBlackList = () => {
        fetcher(`${C.localPlotApiURI}/localFeed/getBlackList`)
            .then(response => response.json())
            .then(data => setBlackListFeed(data));
    };

    const handleEditRow = (id) => {
        setNewFeed({
            host: localFeedList[id].host,
            port: localFeedList[id].port,
            notes: localFeedList[id].notes
        });
        setEditableId(id)
    }

    const handleSaveRow = (id) => {
        if (!isNewFeedValid()) {
            return;
        }

        let fetchData = {
            method: 'POST',
            body: JSON.stringify({...newFeed, id}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        fetcher(`${C.localPlotApiURI}/localFeed/updateFeed`, fetchData)
            .then(response => response.json())
            .then((data) => {
                setLocalFeedList(data);
                handleClearChanges();
            });
    }

    const handleDeleteRow = (id) => {
        let fetchData = {
            method: 'POST',
            body: JSON.stringify({id}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        fetcher(`${C.localPlotApiURI}/localFeed/removeFeed`, fetchData)
            .then(response => response.json())
            .then((data) => {
                setLocalFeedList(data);
            });
    }

    const handleAddRow = () => {

        if (!isNewFeedValid()) {
            return;
        }

        let fetchData = {
            method: 'POST',
            body: JSON.stringify(newFeed),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        fetcher(`${C.localPlotApiURI}/localFeed/addFeed`, fetchData)
            .then(response => response.json())
            .then((data) => {
                setLocalFeedList(data);
                handleClearChanges();
            });
    }

    const handleClearChanges = () => {
        setNewFeed({host: '', port: '', notes: ''});
        setErrorField({host: false, port: false});
        setErrorMessage('');
        if (editableId) {
            setEditableId(null);
        }
    }

    const isNewFeedValid = () => {
        if (newFeed.host === '' || newFeed.port === '') {
            setErrorField({host: newFeed.host === '', port: newFeed.port === ''})
            setErrorMessage('Check the required fields');
            return false;
        }

        if (blackListFeed.includes(newFeed.host)) {
            setErrorField({host: true, port: false})
            setErrorMessage('Not supported host');
            return false;
        }

        const regex = RegExp('^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$');
        if (!newFeed.port.match(regex)) {
            setErrorField({host: false, port: true})
            setErrorMessage('Invalid port range');
            return false;
        }

        return true;
    };

    const renderEditableRow = () => (<>
        <TableCell component="th" scope="row">
            <TextField
                id="newHost"
                variant="standard"
                value={newFeed.host}
                error={errorField.host}
                onChange={(e) => setNewFeed({...newFeed, host: e.target.value})}
            />
        </TableCell>
        <TableCell>
            <TextField
                id="newPort"
                variant="standard"
                value={newFeed.port}
                error={errorField.port}
                onChange={(e) => setNewFeed({...newFeed, port: e.target.value})}
            />
        </TableCell>
        <TableCell>
            <TextField
                id="newNotes"
                variant="standard"
                value={newFeed.notes}
                onChange={(e) => setNewFeed({...newFeed, notes: e.target.value})}
            />
        </TableCell>
    </>);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Host*</TableCell>
                            <TableCell>Port*</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(localFeedList).map((id) => (
                            <TableRow key={id}>
                                {editableId && editableId === id
                                    ? renderEditableRow()
                                    : <>
                                        <TableCell component="th" scope="row">
                                            {localFeedList[id].host}
                                        </TableCell>
                                        <TableCell>
                                            {localFeedList[id].port}
                                        </TableCell>
                                        <TableCell>
                                            {localFeedList[id].notes}
                                        </TableCell>
                                    </>
                                }
                                <TableCell align="center">
                                    {editableId && editableId === id
                                        ? <>
                                            <IconButton onClick={() => handleSaveRow(id)}><SaveIcon/></IconButton>
                                            <IconButton onClick={() => handleClearChanges()}><ClearIcon/></IconButton>
                                        </>
                                        : <>
                                            <IconButton onClick={() => handleEditRow(id)}
                                                        disabled={editableId && editableId !== id}><EditIcon/></IconButton>
                                            <IconButton onClick={() => handleDeleteRow(id)}><DeleteIcon/></IconButton>
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        {!editableId && Object.keys(localFeedList).length < 5 ?
                            <TableRow>
                                {renderEditableRow()}
                                <TableCell align="center">
                                    <IconButton onClick={handleAddRow}><AddIcon/></IconButton>
                                    <IconButton onClick={handleClearChanges}><ClearIcon/></IconButton>
                                </TableCell>
                            </TableRow>
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={errorMessage !== ''}
                onClose={handleCloseErrorMessage}
                autoHideDuration={10000}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </>

    );
};

export default LocalFeedForwarder;
