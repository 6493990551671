import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ConnectionStatus from './components/connectionStatus';
import ConnectionSetup from './components/connectionSetup';

const NetworkSettings = () => {

    return (
        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom align="center">Network Settings</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
                <ConnectionStatus />
            </Grid>
            <Grid item xs={12} md={8}>
                <ConnectionSetup />
            </Grid>
        </Grid>
    );
};

export default NetworkSettings;
