import React, {useEffect, useState, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {fetcher} from '../../../../../utils';

import C from '../../../../configuration';

const TableStatistics = () => {
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const [message, setMessage] = useState(null);

    useEffect(() => {
        getMessageTypeCounters();
        let messageTypeCounterInterval = setInterval(() => {
            getMessageTypeCounters();
        }, 60000);

        return () => {
            clearInterval(messageTypeCounterInterval);
            messageTypeCounterInterval = 0;
        };
    }, []);

    const getMessageTypeCounters = () => {
        fetcher(`${C.localPlotApiURI}/ais/messageTypeCounters`)
            .then(response => response.json())
            .then(data => {
                setMessage(JSON.stringify(data))
            });
    };

    const rows = useMemo(() => {
        return Object.values(JSON.parse(message || '{}')).map((row, index) => (
                <StyledTableRow key={index}>
                    <TableCell component="th" scope="row">
                        {row.description}
                    </TableCell>
                    <TableCell align="center">{row.count}</TableCell>
                </StyledTableRow>
        ));
    }, [message]);
    return (
        message
            ?
            <TableContainer component={Paper}>
                <Toolbar>
                    <Typography variant="h6" component="div">
                        Message type counters per minute
                    </Typography>
                </Toolbar>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
            : null
    );
};

export default TableStatistics;
