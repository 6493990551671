const classes = {
    root:  {
        padding: 2,
        minHeight: 200,
    },
    wifiList: {
        height: 300,
        overflowY: 'auto'
    },
    circularProgress: {
        marginLeft: 4
    },
    popoverBody: {
        padding: 8,
    },
    popoverTextField: {
        marginBottom: 8
    },
};

export {classes};
