import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {NavLink} from 'react-router-dom';

import List from '@mui/material/List';

const MenuItems = ({routes}) => {
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1;
    };
    const [selectedIndex, setSelectedIndex] = React.useState(() => {
            const index = routes.indexOf(routes.filter(route => activeRoute(`${route.layout + route.path}`))[0]);
            return index > 0 ? index : 0;
        }
    );
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const getListItems = () => routes.map((prop, index) => {
        return (
            <ListItemButton
                component={NavLink}
                to={prop.layout + prop.path}
                key={prop.path}
                activeclassname="selectedLink"
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
            >
                <ListItemIcon>
                    <prop.icon/>
                </ListItemIcon>
                <ListItemText primary={prop.name}/>
            </ListItemButton>
        )
    });
    return (
        <List
            style={{marginTop: 5}}
        >
            {getListItems()}
        </List>
    );
};

export default MenuItems;
