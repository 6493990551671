import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'

import { fetcher } from '../../../../../../utils';

import C from '../../../../../configuration';

HighchartsMore(Highcharts)

let positionsPolarInterval;
const PositionsCounterPolar = () => {
    const [isChartInitialized, setChartInitialized] = useState(false);
    const [polarChartOptions, setPolarChartOptions] = useState(null);

    useEffect(() => {
        getPositionsPolar(initializeChart);
        return () => {
            clearInterval(positionsPolarInterval);
            positionsPolarInterval = 0;
        };
    }, []);

    const initializeChart = ({classAArea, classBArea}) => {
        setPolarChartOptions({
            chart: {
                polar: true,
                events: {
                    load: function () {
                        const classAAreaSeries = this.series[0];
                        const classBAreaSeries = this.series[1];
                        const chart = this;
                        positionsPolarInterval = setInterval(() => {
                            getPositionsPolar(({classAArea, classBArea}) => {
                                classAAreaSeries.setData(classAArea);
                                classBAreaSeries.setData(classBArea);
                                chart.redraw();
                            });
                        }, 60000);
                    }
                }
            },
            title: {
                text: 'Number of positions'
            },
            pane: {
                startAngle: 0,
                endAngle: 360
            },
            xAxis: {
                tickInterval: 45,
                min: 0,
                max: 360,
                labels: {
                    format: '{value}°'
                }
            },
            yAxis: {
                min: 0
            },
            plotOptions: {
                series: {
                    pointStart: 0,
                    pointInterval: 10,
                    animation: false
                },
                column: {
                    pointPadding: 0,
                    groupPadding: 0
                }
            },
            series: [{
                type: 'area',
                name: 'Class A',
                data: classAArea
            }, {
                type: 'area',
                name: 'Class B',
                data: classBArea
            }]
        });
        setChartInitialized(true);
    };
    const getPositionsPolar = (cb) => {
        fetcher(`${C.localPlotApiURI}/ais/positionsPolar`)
            .then(response => response.json())
            .then(data => {
                cb(data);
            });
    };

    return (
        isChartInitialized ?
            <HighchartsReact
                highcharts={Highcharts}
                options={polarChartOptions}
            />
            : null
    );
};

export default PositionsCounterPolar;
