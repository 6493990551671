import { Switch } from '@mui/material';
import grey from '@mui/material/colors/grey';
import {styled} from "@mui/material/styles";


const StyledSwitch = styled(Switch,)(({theme}) => (
    {
        switchBase: {
            color: grey[500],
            '&$checked': {
                color: grey[200],
            },
            '&$checked + $track': {
                backgroundColor: grey[100],
            },
        },
    }
));

export default StyledSwitch;
