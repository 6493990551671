import React, {useEffect, useState} from 'react';

import {
    RootPaper,
    StyledButton as Button,
    ActionArea,
    StyledSelect as Select,
    StyledCircularProgress as CircularProgress
} from './styles';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import IpConfiguration from './components/ipConfiguration';
import WifiConfiguration from './components/wifiConfiguration';
import WifiList from './components/wifiList';

import {fetcher} from '../../../../../utils';

import C from '../../../../configuration';

const ConnectionSetup = () => {
    const steps = ['Choose connection type', 'Configure connection', 'Update connection'];
    const [activeStep, setActiveStep] = useState(0);
    const [connections, setConnections] = useState({});
    const [configuration, setConfiguration] = useState({
        type: 'ethernet',
        interface: '',
        isStaticEnabled: false,
        address: '',
        gateway: '',
        netmask: 24,
        ssid: '',
        password: '',
        isLocked: false,
    });

    useEffect(() => {
        fetcher(`${C.localPlotApiURI}/network/connection/status`)
            .then(response => response.json())
            .then(data => {
                setConnections(data);
                const firstConnection = Object.values(data)[0];
                handleConfigurationChange([
                    {key: 'interface', value: firstConnection.interface},
                    {key: 'type', value: firstConnection.type}
                ])
                handleConfigurationChange('type', Object.values(data)[0].interface);
            });
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
        setConfiguration({
            type: 'ethernet',
            isStaticEnabled: false,
            address: '',
            gateway: '',
            netmask: 24,
            ssid: '',
            password: '',
            isLocked: false,
        });
    };
    const handleConfigurationChange = (changes) => {
        const tmpConfiguration = {...configuration};
        if (Array.isArray(changes)) {
            changes.forEach(({key, value}) => {
                tmpConfiguration[key] = value;
            });
        } else {
            tmpConfiguration[changes.key] = changes.value;
        }
        setConfiguration(tmpConfiguration);
    };
    const handleUpdateConnection = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let fetchData = {
            method: 'POST',
            body: JSON.stringify(configuration),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        fetcher(`${C.localPlotApiURI}/network/connection/update`, fetchData)
            .then(response => handleReset());
    };

    const renderSelectView = () => (<>
        <Typography>
            Select which connection type to update
        </Typography>
        <Select
            variant="standard"
            labelId="connection-type-select-label"
            id="connection-type-select"
            onChange={(e) =>
                handleConfigurationChange([
                    {key: 'interface', value: e.target.value},
                    {key: 'type', value: connections[e.target.value].type}
                ])}
            value={configuration.interface}
        >
            {
                Object.values(connections).map((connection, i) =>
                    <MenuItem value={connection.interface} key={i}>{connection.name}</MenuItem>)
            }
        </Select>
    </>);
    const renderConfigureView = () => (
        <Grid container spacing={2}>
            {configuration.type === 'wifi'
                ? <Grid item xs={6} md={4}>
                    <WifiList
                        onClick={(ssid, isLocked) => {
                            handleConfigurationChange([
                                {key: 'ssid', value: ssid},
                                {key: 'isLocked', value: isLocked}
                            ]);
                        }}
                    />
                </Grid>
                : null
            }
            <Grid item xs>
                {configuration.type === 'wifi'
                    ? <WifiConfiguration
                        ssid={configuration.ssid}
                        password={configuration.password}
                        isLocked={configuration.isLocked}
                        onChange={({key, value}) => {
                            if (key === 'isLocked' && !value) {
                                handleConfigurationChange([
                                    {key, value},
                                    {key: 'password', value: ''},
                                ]);
                            } else {
                                handleConfigurationChange({key, value});
                            }
                        }}
                    />
                    : null
                }
                <IpConfiguration
                    gateway={configuration.gateway}
                    address={configuration.address}
                    netmask={configuration.netmask}
                    isStaticEnabled={configuration.isStaticEnabled}
                    onChange={({key, value}) => {
                        if (key === 'isStaticEnabled' && !value) {
                            handleConfigurationChange([
                                {key, value},
                                {key: 'address', value: ''},
                                {key: 'gateway', value: ''},
                                {key: 'netmask', value: 24},
                            ]);
                        } else {
                            handleConfigurationChange({key, value});
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
    const renderUpdateView = () => (<>
        <Typography>
            Are sure for the connection update?
        </Typography>
        <Typography variant="caption" component="div">
            <b>Type: </b>{configuration.type}
        </Typography>
        {configuration.type === 'wifi'
            ? (
                <Typography variant="caption" component="div">
                    <b>SSID: </b>{configuration.ssid}
                </Typography>
            )
            : null}
        <Typography variant="caption" component="div">
            <b>address mode: </b> {configuration.isStaticEnabled ? 'static' : 'dhcp'}
        </Typography>
        {configuration.isStaticEnabled
            ? (
                <>
                    <Typography variant="caption" component="div">
                        <b>address: </b>{configuration.address}/{configuration.netmask}
                    </Typography>
                    <Typography variant="caption" component="div">
                        <b>gateway: </b>{configuration.gateway}
                    </Typography>
                </>
            )
            : null}
    </>);

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return renderSelectView();
            case 1:
                return renderConfigureView();
            case 2:
                return renderUpdateView();
            default:
                return 'Unknown step';
        }
    }

    const isNextButtonDisabled = () => {
        if (activeStep !== 1) {
            return false;
        }

        const {
            type,
            isStaticEnabled,
            address,
            gateway,
            ssid,
            password,
            isLocked
        } = configuration;

        if (isStaticEnabled && (address.length === 0 || gateway.length === 0)) {
            return true;
        }

        const regex = new RegExp('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
        if (isStaticEnabled && (!address.match(regex) || !gateway.match(regex))) {
            return true;
        }

        return type === 'wifi' && (ssid.length === 0 || (isLocked && password.length === 0));


    };

    return Object.keys(connections).length > 0 ? (
            <>
                <Typography variant="h6" gutterBottom align="center">Setup connection</Typography>
                <RootPaper>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {getStepContent(index)}
                                    <ActionArea>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={activeStep === steps.length - 1 ? handleUpdateConnection : handleNext}
                                            disabled={isNextButtonDisabled()}
                                        >
                                            {activeStep === steps.length - 1 ? 'Update' : 'Next'}
                                        </Button>
                                    </ActionArea>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                            <CircularProgress
                                color="inherit"
                            />
                    )}
                </RootPaper>
            </>
        )
        : null;
};

export default ConnectionSetup;
