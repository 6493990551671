import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Loader from '../../../../../loader';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { fetcher } from "../../../../../utils";
import C from "../../../../configuration";

const MacAddressView = () => {
    const [macData, setMacData] = useState(null);

    useEffect(() => {
        getMacAddressData();
    }, []);

    const getMacAddressData = () => {
        fetcher(`${C.localPlotApiURI}/network/macAddressList`)
            .then(response => response.json())
            .then(data => {
                setMacData(data);
            });
    };

    return (
        <>
            {macData === null
                ? <Loader />
                : <TableContainer component={Paper}>
                    <Typography variant="h4" align="center" gutterBottom style={{marginTop: 4}}>
                        Mac Addresses
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Interface Name</TableCell>
                                <TableCell>Mac Address</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {macData.map((interfaceData) => (
                                <TableRow key={interfaceData.name}>
                                    <TableCell component="th" scope="row">
                                        {interfaceData.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {interfaceData.address}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

export default MacAddressView;
