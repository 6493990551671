import React from 'react';
import Grid from '@mui/material/Grid';

import {RealTimeStatisticsGrid} from './styles';

import Counters from './Counters';
import VesselTypePie from './VesselTypePie';

const RealtimeStatisticsOverview = () => {

    return (
            <RealTimeStatisticsGrid
                container
                alignItems="center"
                justifyContent="center"
            >
                <Grid item md={3}>
                    <Counters/>
                </Grid>
                <Grid item md={9}>
                    <VesselTypePie/>
                </Grid>
            </RealTimeStatisticsGrid>
    );
};

export default RealtimeStatisticsOverview;
