import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper,)(({theme}) => (
    {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
));

export {StyledPaper};
