import React from 'react';
import IconButton from '@mui/material/IconButton';
import PowerSettingsIcon from '@mui/icons-material/PowerSettingsNew';
import RebootIcon from '@mui/icons-material/Refresh';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { fetcher } from '../../../../../utils';

import C from '../../../../configuration';

let agreeButtonTimeout = null;
const PowerButton = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});
    const [dialog, setDialog] = React.useState({
        open: false, title: '', description: '', action: ''
    });
    const [isAgreeButtonDisabled, setIsAgreeButtonDisabled] = React.useState(true);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'success'});
    };

    const handleCloseDialog = () => {
        setDialog({open: false, title: '', description: '', action: ''});

        if (agreeButtonTimeout !== null) {
            clearTimeout(agreeButtonTimeout);
            agreeButtonTimeout = null;
        }

        if (!isAgreeButtonDisabled) {
            setIsAgreeButtonDisabled(true);
        }
    };

    const handleAction = (action) => {
        handleCloseDialog();
        let successMessage = '';
        let errorMessage = '';
        switch (action) {
            case 'shutdown': {
                successMessage = 'The device is shutting down';
                errorMessage = 'Device shutdown failed';
                break;
            }
            case 'reboot': {
                successMessage = 'Device is rebooting. It may take several minutes to complete.';
                errorMessage = 'Device rebooting failed';
                break;
            }
            default:
        }

        fetcher(`${C.localPlotApiURI}/system/${action}`, {method: 'POST'})
            .then((response) => {
                if (response.status === 200) {
                    setSnackbar({open: true, message: successMessage, severity: 'success'});
                } else {
                    setSnackbar({open: true, message: errorMessage, severity: 'error'});
                }
            })
            .finally(() => {
                handleClose();
            });
    }

    if (dialog.open && agreeButtonTimeout === null) {
        agreeButtonTimeout = setTimeout(() => {
            setIsAgreeButtonDisabled(false);
        }, 3000);
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <PowerSettingsIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setDialog({
                        open: true,
                        title: 'Are you sure want to shutdown the device?',
                        description: 'By shutting down the device, you will lose connectivity and manual power cycle will be required.',
                        action: 'shutdown'
                    });
                }}>
                    <ListItemIcon>
                        <PowerSettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Shutdown</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    setDialog({
                        open: true,
                        title: 'Are you sure want to reboot the device?',
                        description: 'Rebooting the device without reason might cause issues.',
                        action: 'reboot'
                    });
                }}>
                    <ListItemIcon>
                        <RebootIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Reboot</ListItemText>
                </MenuItem>
            </Menu>
            {snackbar.open
                ?
                <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                : null
            }
            {dialog.open
                ? <Dialog
                    open={dialog.open}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialog.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog.description}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>
                            Disagree
                        </Button>
                        <Button
                            disabled={isAgreeButtonDisabled}
                            onClick={() => handleAction(dialog.action)}
                        >
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                : null
            }
        </div>
    );
};

export default PowerButton;
