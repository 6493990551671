import React, {useEffect, useState} from 'react';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {classes} from './styles';
import {fetcher} from "../../../../../utils";
import C from "../../../../configuration";

let inputStatusInterval;
const InputStatusView = () => {
    const [hasAISData, setHasAISData] = useState(false);
    const [maxDistance, setMaxDistance] = useState(0);

    useEffect(() => {
        getHasAISData();
        getMaxDistance();
        inputStatusInterval = setInterval(() => {
            getHasAISData();
            getMaxDistance();
        }, 10000);
        return () => {
            clearInterval(inputStatusInterval);
            inputStatusInterval = 0;
        };
    }, []);

    const getHasAISData = () => {
        fetcher(`${C.localPlotApiURI}/ais/hasAISData`)
            .then(response => response.json())
            .then(data => setHasAISData(data));
    };

    const getMaxDistance = () => {
        fetcher(`${C.localPlotApiURI}/ais/getMaxDistance`)
            .then(response => response.json())
            .then(data => setMaxDistance(data));
    };

    return (
            <TableContainer component={Paper}>
                <Typography variant="h4" align="center" gutterBottom style={{marginTop: 4}}>
                    Input Status
                </Typography>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Has AIS Input
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {hasAISData ?
                                    <CheckCircleIcon sx={classes.pass}/>
                                    : <CancelIcon sx={classes.noPass}/>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Max Distance (NM)
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {maxDistance}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    );
}

export default InputStatusView;
